import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { updateProfile } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ar';
import { withTranslation } from 'react-i18next';
import fbConfig from '../../config/fbConfig';
import i18n from '../../i18next';


class UpdateProfile extends Component {
    state = {
        email: "",
        firstName: "",
        lastName: "",
        position: "",
        file: "",
        imageUrl: "",
        imageName: "",
        isUpodated: false,
        isloadingImage: false
    }
    handleImageChange = (e) => {
        if (e.target.files[0] !== undefined){
            if (this.state.file !== ""){
                this.handleDeleteImage(e);
                this.setState({
                    file: e.target.files[0],
                    isUpodated: true,
                    isloadingImage: true
                });
            } else {
                this.setState({
                    file: e.target.files[0],
                    isUpodated: true,
                    isloadingImage: true
                });
            }
                
        }
    }
    handleUploadImage = () => {
        const imageNickName = Date.now();
        const FinalimageNickName = imageNickName+this.state.file.name
        const bucketName = 'images/usersimages';
        const file = this.state.file;
        const storageRef =  fbConfig.storage().ref(`${bucketName}/${FinalimageNickName}`);
        const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/usersimages/'+FinalimageNickName);
        const uploadTask = storageRef.put(file);
        
            uploadTask
            .then(()=>{
                storageRefOfCurrentFile.getDownloadURL()
                .then((url) => {
                    this.setState({
                        imageUrl: url,
                        imageName:FinalimageNickName,
                        isloadingImage: false
                    });
                });
            });
    }
    handleDeleteImage = (e) => {
        e.preventDefault();
        const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/usersimages/'+this.state.imageName);
        
        storageRefOfCurrentFile.delete()
            .then(()=>{
                this.setState({
                    imageUrl: "",
                    imageName: "",
                    file: "",
                    isUpodated: true
                });
                this.props.updateProfile(this.state);
                });
            
        //storage.ref().child(uid).child(img).delete()
        //database.ref().child(uid).child(img).remove()
      }
    componentDidUpdate(prevProps, prevState){
        if(prevState.file !== this.state.file && this.state.file !== ""){
            this.handleUploadImage();
        }
        if(prevProps.profile !== this.props.profile){
            this.setState({
                email: this.props.profile.email,
                firstName: this.props.profile.firstName,
                lastName: this.props.profile.lastName,
                position: this.props.profile.position,
                imageUrl: this.props.profile.imageUrl,
                imageName:this.props.profile.imageName ? this.props.profile.imageName : "",
            });
        }  
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            isUpodated: true
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        // submit edited Profile
        this.props.updateProfile(this.state);
        this.props.history.push('/' + i18n.language + '/user/' + this.state.firstName + '-' + this.state.lastName + '/' + this.props.auth.uid);
    }
    
    render(){
        const { profile, auth, t, i18n } = this.props;
        //console.log(profile)
        moment.locale(i18n.language);
    if (!auth.uid) {
        return <Redirect to={'/' + i18n.language} />
    } else {
        if (profile){
            return(
                <div className="main-section-holder">
                    <form onSubmit={this.handleSubmit} className={i18n.language === 'ar' ? 'white tar arDr' : 'white tal'}>
                        <h5 className="grey-text text-darken-3">{t('Global.EditProfile')}</h5>
                        <div className="input-field">
                            <label htmlFor="firstName" className={i18n.language === 'ar' ? 'autoLabel-right active' : 'active'}>{t('Global.FirstName')}</label>
                            <input type="text" id="firstName" onChange={this.handleChange} value={this.state.firstName}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="lastName" className={i18n.language === 'ar' ? 'autoLabel-right active' : 'active'}>{t('Global.LastName')}</label>
                            <input type="text" id="lastName" onChange={this.handleChange} value={this.state.lastName}/>
                        </div>
                        <div className="input-field">
                            <div className='btns-area'>
                            <input id="image" type='file' onChange={this.handleImageChange}/>
                            { this.state.imageUrl !== "" &&<button onClick={this.handleDeleteImage} className="btn delete-photo red">
                            {t('Global.DeleteImage')}
                            </button>}
                            </div>
                            {this.state.isloadingImage ?
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                            :    
                        
                            this.state.imageUrl !== "" ? <img src={this.state.imageUrl} alt={this.state.title} id="project-image"/> : null 
                            }
                            </div>
                        <div className="input-field">
                        {t('Global.Position')}: {profile.position}
                        </div>
                        <div className="input-field">
                            <button className={this.state.isUpodated ? "btn green lighten-1 z-depth-0 waves-effect waves-light" : "btn green lighten-1 z-depth-0 waves-effect waves-light disabled"}>
                            {t('Global.Update')}
                            </button>
                        </div>
                    </form>
                </div>
            )
        } else{
            return(
            <div className='main-section-holder center'>
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
            )
        }
    }
    }
}

const mapStateToProps = (state, ownProps) => {
    //console.log(i18n.language);
    const id = ownProps.match.params.id;
    const users = state.firestore.data.users;
    const profile = users? users[id] : null;
    return{
        profile : profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        updateProfile: (profile) => dispatch(updateProfile(profile))
    }
}



export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((Props, State) => {
        return [
        {collection: 'users'}
    ]})
)(withTranslation()(UpdateProfile));