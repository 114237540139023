import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faUserTie, faProjectDiagram, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

const SignedInLinks = (props) =>{

    const { t, i18n } = useTranslation();
    const culture = i18n.language;
    //console.log(props);
    return (
        <div>
            <div className='user-area'>
            <NavLink to={'/' + culture + '/user/'+ props.profile.firstName + '-' + props.profile.lastName + '/' + props.auth.uid} className='user-photo pink lighten-1'>
                {props.profile.imageUrl 
                ? <img src={props.profile.imageUrl} alt={props.profile.firstName + " " + props.profile.lastName}/>
                : props.profile.initials
                }
            </NavLink>
            </div>
            <ul>
            <li><NavLink to={'/' + culture + '/dashboard'}><FontAwesomeIcon icon={faProjectDiagram} />{t('Global.AllProducts')}</NavLink></li>
            <li><NavLink to={'/' + culture + '/featuresdashboard'}><FontAwesomeIcon icon={faProjectDiagram} />{t('Global.AllFeatures')}</NavLink></li>
            <li><NavLink to={'/' + culture + '/dashboardabout/about-us/' + (culture === "ar" ? "E8D17umPVbR1vrwO6gLq" : "XZ1jopzynp7SsnjfTpXx")}><FontAwesomeIcon icon={faQuestion} />{t('Global.AboutUs')}</NavLink></li>
            {props.profile.position === "adminstrator" && <li><NavLink to={'/' + culture+'/usersdashboard'}><FontAwesomeIcon icon={faUserTie} />{t('Global.AllUsers')}</NavLink></li>}
            <li><a onClick={props.signOut}><FontAwesomeIcon icon={faSignOutAlt} />{t('Global.LogOut')}</a></li>
            </ul>
        </div>
        
    )
}

const mapStateToProps = (state) => {
    //console.log(state);
    //console.log(i18n.language);
    return {
        auth: state.firebase.auth
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks);