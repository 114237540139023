import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createFeature } from '../../store/actions/featureActions';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import fbConfig from '../../config/fbConfig';
import JoditEditor from "jodit-react";
import i18n from '../../i18next';
//import { Beforeunload  } from 'react-beforeunload';

let FinalimageNickName = "";

class CreateFeature extends Component {
    state = {
        title: "",
        content: "",
        shortInfo:"",
        file: "",
        localImageUrl: "",
        imageUrl: "",
        imageName:"",
        isLoading: false,
        isSubmit: false,
        isEmpty: true
    }
    handleChange = (e) => {
        if(e.target.value === "" || this.state.content === ""){
            this.setState({
                [e.target.id]: e.target.value,
                isEmpty: true
            })
        }else{
            this.setState({
                [e.target.id]: e.target.value,
                isEmpty: false
            })
        }
        
       
    }
    handleChangeTextarea = (value) => {
        if(value === "" || this.state.title === ""){
            this.setState({
                content: value,
                isEmpty: true
            })
        }else{
            this.setState({
                content: value,
                isEmpty: false
            })
        }
        
    }
    
    handleImageChange = (e) => {
        if (e.target.files[0] !== undefined){
            var file = e.target.files[0];
                var reader = new FileReader();
                reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                this.setState({
                    localImageUrl: [reader.result],
                    file: file
                    })
                }.bind(this);
            }
                
        
    }

    handleUploadImage = () => {
        const imageNickName = Date.now();
        FinalimageNickName = imageNickName+this.state.file.name
        const bucketName = 'images/featursimages';
        const file = this.state.file;
        const storageRef =  fbConfig.storage().ref(`${bucketName}/${FinalimageNickName}`);
        const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/featursimages/'+FinalimageNickName);
        const uploadTask = storageRef.put(file);
        // another way with axios
        // const data = new FormData()
        // axios.post('firebase link',  data)
        // data.append('file', this.state.file);
        // console.log(data);
        // axios.post("http://localhost:8000/upload", data, { // receive two parameter endpoint url ,form data 
        //     })
        //     .then(res => { // then print response status
        //     console.log(res.statusText)
        //     })
        
            uploadTask
            .then(()=>{
                storageRefOfCurrentFile.getDownloadURL()
                .then((url) => {
                    this.setState({
                        imageUrl: url,
                        imageName:FinalimageNickName,
                        isLoading: false
                    });
                    this.props.createFeature(this.state);
                    this.props.history.push('/' + i18n.language + '/featuresdashboard');
                });
            });
    }
    handleDeleteImage = (e) => {
        e.preventDefault();
        const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/featursimages/'+FinalimageNickName);
        
        storageRefOfCurrentFile.delete()
            .then(()=>{
                this.setState({
                    imageUrl: "",
                    file: ""
                });
                document.getElementById('image').value = ""
                });
            
        //storage.ref().child(uid).child(img).delete()
        //database.ref().child(uid).child(img).remove()
      }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isSubmit: true});
        if(this.state.file !== ""){
            this.handleUploadImage();
            
        }else{
            this.props.createFeature(this.state);
            this.props.history.push('/' + i18n.language + '/featuresdashboard');
        }
        
        
        //
        
    }

    render(){
        
        const { auth, t, i18n } = this.props;
        if (!auth.uid) {
            return <Redirect to={'/' + i18n.language} />
        } else{
            return(
                <div className="main-section-holder">
                    <form onSubmit={this.handleSubmit} className={i18n.language === 'ar' ? 'product-form white tar arDr' : 'product-form white tal'}>
                        {this.state.isSubmit ? 
                            <div className="loading-submit">
                                <img src="/images/loading.svg" alt="Loading" />
                            </div>
                        : null}
                        <h5 className="grey-text text-darken-3">{t('CreateFeature.CreateFeature')}</h5>
                        <div className="input-field">
                            <label htmlFor="title" className={i18n.language === 'ar' ? 'autoLabel-right' : ''}>{t('Global.Title')}</label>
                            <input type="text" id="title" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="shortInfo" className={i18n.language === 'ar' ? 'autoLabel-right' : ''}>{t('Global.ShortInfo')}</label>
                            <input type="text" id="shortInfo" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <JoditEditor value="" onChange={this.handleChangeTextarea}/>
                        </div>
                        <div className="input-field">
                            <input id="image" type='file' onChange={this.handleImageChange}/>
                        </div>
                        <div className='image-holder'>
                            {this.state.isLoading === true &&
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                            }
                            {this.state.localImageUrl !== "" ? <img src={this.state.localImageUrl} alt={this.state.title} id="project-image"/> : null }
                        </div>
                        <div className="input-field">
                            <button className="btn pink lighten-1 z-depth-0 waves-effect waves-light" disabled={this.state.isEmpty}>
                            {t('CreateFeature.Create')}
                            </button>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) =>{
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        createFeature: (feature) => dispatch(createFeature(feature))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateFeature));
  