import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Notifications from './Notifications';
import ProjectList from '../projects/ProjectList';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';


class Dashboard extends Component{
    render(){
        const { projects, auth, i18n, t } = this.props;
        //console.log(this.props);
        if(!auth.uid){
            return <Redirect to={'/' + i18n.language} />
        } else{
            return(
                <div className={i18n.language === 'ar' ? 'dashboard main-section-holder tar arDr' : 'dashboard main-section-holder'}>
                    <div className='row'>
                        <div className='col s12 m12'>
                        <Link className="new-item" to={'/' + i18n.language+'/createproject'}><FontAwesomeIcon icon={faPlusSquare} />{t('Global.NewProduct')}</Link>
                            {isLoaded(projects)
                            ? projects.length > 0 ?
                            <ProjectList projects={projects} />
                            : 
                            <p>
                                {t('Global.ThereIsNoFeature')}
                            </p>
                            :
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                            }
                            
                        </div>
                        {/* <div className='col s12 m5 offset-m1'>
                        {isLoaded(notifications)
                        ? 
                            <Notifications notifications={notifications} />
                        : 
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                        }
                            
                        </div> */}
                    </div>
                </div>
            )
        } 
    }
}
const mapStateToProps = (state) =>{
    //console.log(state);
    //console.log(i18n.language)
    return {
        projects: i18n.language === 'en' ? state.firestore.ordered.projects : state.firestore.ordered.projectsAr,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        //console.log(props);
        //console.log(State);
        return [
        { collection: i18n.language === 'en' ? 'projects' : 'projectsAr',
        //where: [['file', '==', 'uploaded']
        orderBy: ['createdAt', 'desc']
        
    }
    ]})
)(withTranslation()(Dashboard));