import React from 'react';
import UserSummary from './UserSummary';
import { Link } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';

const UserList =({users}) =>{
    
    return(
        <div className='users-list section'>
            <ul className="collection">
                { users && users.map(user=>{
                    return(
                        <li className="collection-item" key={user.id}>
                        <Link to={'user/'+ user.firstName + '-' + user.lastName + '/' + user.id}>
                            <UserSummary user={user} />
                        </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default UserList;