import React from 'react';
import { useTranslation } from 'react-i18next';

const Slogan = () => {
    const { t } = useTranslation();
    return(
        <div className="slogan">
            "{t("Global.Slogan")}"
        </div>
    )
}


export default Slogan;