import React from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';
import {deleteFeature} from '../../store/actions/featureActions'
import fbConfig from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';

const FeatureDetail = (props) =>{

    const { feature, auth } = props;
    const { t, i18n } = useTranslation();

    //console.log(feature);

    const handleDelete = () => {
            const id = props.match.params.id;
            const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/featuresimages/'+feature.imageName);
            if(feature.imageUrl !== ""){
                storageRefOfCurrentFile.delete();
                props.deleteFeature(id);
            }else{
                props.deleteFeature(id);
            }
            
            props.history.push('/' + i18n.language + '/featuresdashboard');
            // you can push to dashboard after deleting...
    }
        moment.locale(i18n.language);
        if (feature){
            if(!auth.uid) {
                return <Redirect to={'/' + i18n.language} />
            }
            else{
                return(
                    <div className='main-section-holder section project-details'>
                        <Helmet>
                            <title>{feature.title}</title>
                            <meta name="description" content={feature.content} />
                        </Helmet>
                        <div className={i18n.language === 'ar' ? 'card z-depth-0 tar arDr' : 'card z-depth-0'}>
                            { feature.imageUrl !== "" &&
                            <div className='card-image'>
                                <img src={feature.imageUrl} alt={feature.title} />
                            </div>
                            }
                            <div className='card-content'>
                                <h1 className='card-title'>{feature.title}</h1>
                                <p dangerouslySetInnerHTML={{__html: feature.content}}></p>
                            </div>
                            <div className='card-action gret liten-4 grey-text'>
                                <div>{t('Global.PostedBy')}: {feature.authorFirstName} {feature.authorLastName}</div>
                                <div>{moment(feature.createdAt.toDate()).calendar()}</div>
                            </div>
                            {auth.uid !== undefined &&
                            <div className='action-btns-sec card-action'>
                            <button className="waves-effect waves-light btn red" onClick={()=> {handleDelete()}}>
                                {t('Global.Delete')} <FontAwesomeIcon icon={faTrashAlt}/>
                                </button>
                            <a href={"/" + i18n.language + '/editFeature/' + feature.title.replace(/\s+/g, '-').toLowerCase() + '/' + props.match.params.id} className="waves-effect waves-light btn blue">
                                {t('Global.Edit')} <FontAwesomeIcon icon={faEdit}/>
                                </a>
                            </div>
                            }
                            
                        </div>
                    </div>
                )
            }
            
        } else{
            return(
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            )
        }
    
    
}

const mapStateToProps = (state, ownProps) => {
    
    //console.log(i18n.language);
    const id = ownProps.match.params.id;
    const arFeatures = state.firestore.data.featuresAr;
    const enFeatures = state.firestore.data.features;
    const features = {
        ...arFeatures,
        ...enFeatures
    };
    const feature = features ? features[id] : null;
    return{
        feature : feature,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteFeature: (id) => dispatch(deleteFeature(id))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        return [
        {collection: 'features'},
        {collection: 'featuresAr'}
    ]})
)(FeatureDetail);