import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class FeaturesList extends Component {
    state = {
        features: "",
        isFiltered: false
    }
    handleChange = (e) =>{
        const features = this.props.features;
        const filteredFeatures = features.filter( feature => {
        if(e.target.value.length > 0){
            return feature.title.includes(e.target.value);
        }else{
                return feature;
            }       
        })
        this.setState({
            features: filteredFeatures,
            isFiltered: true
        })
        
    }
    render(){
        const { i18n, t } = this.props;
        const features = this.state.isFiltered ? this.state.features : this.props.features;
        return(
            <section className="page-section" id="features">
                <div className="container">
                    <div className="row">
                        { features && features.map(feature=>{
                            return(
                                <div className="col m4" key={feature.id}>
                                    <div className={i18n.language === "en" ? "mt-5 single-product english" : "mt-5 single-product"}>
                                        <h3 className="h4 mb-2">{feature.title}</h3>
                                        <p className=" mb-0">
                                            {feature.shortInfo}
                                        </p>
                                        <Link to={'/'+i18n.language +'/featuredetail/'+ feature.title.replace(/\s+/g, '-').toLowerCase()  + '/' + feature.id} >
                                            <span>{t('Global.ReadMore')}</span>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(FeaturesList);