import React from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import {deleteFeature} from '../../../store/actions/featureActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Map from "../../home/map/Map";
import Slogan from "../../home/slogan/Slogan";

const FeatureDetail = (props) =>{
const { feature } = props;
const { t, i18n } = useTranslation();

   //console.log(feature);
        if (feature){
            return(
                <div className='product-page-holder'>
                    <div className={i18n.language === 'ar' ? 'card z-depth-0 tar arDr' : 'card z-depth-0'}>
                        { feature.imageUrl !== "" ?
                        <div className='card-image'>
                            <img src={feature.imageUrl} alt={feature.title} />
                        </div>
                        :
                        <div className='card-image'>
                            <img src="/images/loading.svg" alt="Loading" />
                        </div>
                        }
                        <div className='card-content'>
                        <Helmet>
                            <title>{feature.title}</title>
                            <meta name="description" content={feature.content} />
                        </Helmet>
                            <div className='card-title'>
                                <div className="container">
                                    <h1>
                                        {feature.title}
                                    </h1>
                                </div>
                            </div>
                            <div className="card-article">
                                <div className="container">
                                    <div className="article-holder">
                                        <article dangerouslySetInnerHTML={{__html: feature.content}}>
                                        </article>
                                    <div className={i18n.language === 'ar' ? "contact-methods arDr" : "contact-methods"}>
                                        <p>{t('ProductPage.ForMoreInfo')}</p>
                                        <ul className="contact-methods-icons">
                                                <li>
                                                    <a href="tel:+905308939477"><FontAwesomeIcon icon={faPhone} /></a>
                                                </li>
                                                <li>
                                                    <a href="mailto:info@ilaf-trade.com">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.facebook.com/ilaftrade" target="_blank" rel="noopener noreferrer">
                                                        <FontAwesomeIcon icon={faFacebook} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/ilaftrade/" target="_blank" rel="noopener noreferrer">
                                                        <FontAwesomeIcon icon={faInstagram} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://api.whatsapp.com/send?phone=+905308939477&text=مرحباً, أنا أتواصل معكم عن طريق موقعكم الالكتروني">
                                                        <FontAwesomeIcon icon={faWhatsapp} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/ilaf-trade-company-1672811b0/">
                                                        <FontAwesomeIcon icon={faLinkedin} />
                                                    </a>
                                                </li>
                                            </ul>
                                    </div>
                                    </div>
                                </div>
                               
                            </div>
                            <Slogan />
                            <Map />
                        </div>
                    </div>
                </div>
            )
        } else{
            return(
            
                <div className="blank-loading">
                    <img src="/images/loading.svg" alt="loading ..." />
                </div>
            
            )
        }
    
    
}

const mapStateToProps = (state, ownProps) => {
    
    //console.log(i18n.language);
    const id = ownProps.match.params.id;
    const arFeatures = state.firestore.data.featuresAr;
    const enFeatures = state.firestore.data.features;
    const features = {
        ...arFeatures,
        ...enFeatures
    };
    const feature = features ? features[id] : null;
    return{
        feature : feature,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteFeature: (id) => dispatch(deleteFeature(id))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        return [
        {collection: 'features'},
        {collection: 'featuresAr'}
    ]})
)(FeatureDetail);