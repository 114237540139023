import React from 'react';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';


const ErrorPage = (props) => {
    const {t} = useTranslation();
    return (
        <div className='section center'>
            <h1>{t('Global.404Error')}</h1>
        </div>
    )
}


export default ErrorPage;