import React, {Component} from 'react';
import Slides from './Slides';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';


class HomeSlider extends Component {
    state = {
        projects: "",
        isFiltered: false
    }
    handleChange = (e) =>{
        const projects = this.props.projects;
        const filteredProjects = projects.filter( project => {
        if(e.target.value.length > 0){
            return project.title.includes(e.target.value);
        }else{
                return project;
            }       
        })
        this.setState({
            projects: filteredProjects,
            isFiltered: true
        })
        
    }
    render(){
        function PrevArrow(props) {
            const { className, onClick } = props;
            return (
                <FontAwesomeIcon
                className={className}
                onClick={onClick}
                icon={faChevronLeft} />
            );
        }
        function NextArrow(props) {
            const { className, onClick } = props;
            return (
                <FontAwesomeIcon
                className={className}
                onClick={onClick}
                icon={faChevronRight} />
            );
        }
        const SliderSettings = {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: true,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            responsive: [{
            breakpoint: 768,
            settings: {
                arrows: true,
                dots: false
                }
            }]
        };
        const { i18n } = this.props;
        const projects = this.state.isFiltered ? this.state.projects : this.props.projects;
        return(
            <div className="slider-section">
                    <Slider {...SliderSettings} className="slider-item baners-slider">
                    { projects && projects.map(project=>{
                        return(
                            <Link to={'/'+i18n.language +'/product/'+ project.title.replace(/\s+/g, '-').toLowerCase()  + '/' + project.id}  key={project.id}>
                                <Slides project={project} />
                            </Link>
                        )
                    })}
                    </Slider>
            </div>
            
        )
    }
}

export default withTranslation()(HomeSlider);