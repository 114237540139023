import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyBlXMZI08cLlYVZvp_82dVxF3YTXD6GlRs",
  authDomain: "ilaf-trade.firebaseapp.com",
  databaseURL: "https://ilaf-trade.firebaseio.com",
  projectId: "ilaf-trade",
  storageBucket: "ilaf-trade.appspot.com",
  messagingSenderId: "184149540598",
  appId: "1:184149540598:web:48b2760ede4b589ae6b6d7"
};

firebase.initializeApp(config);
firebase.firestore().settings({experimentalForceLongPolling: true})

export default firebase;