import React, {Component} from 'react';
import FeatureSummary from './FeatureSummary';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class FeaturesList extends Component {
    state = {
        features: "",
        isFiltered: false
    }
    handleChange = (e) =>{
        const features = this.props.features;
        const filteredFeatures = features.filter( feature => {
        if(e.target.value.length > 0){
            return feature.title.toLowerCase().includes(e.target.value.toLowerCase());
        }else{
                return feature;
            }       
        })
        this.setState({
            features: filteredFeatures,
            isFiltered: true
        })
        
    }
    render(){
        const { i18n, t } = this.props;
        const features = this.state.isFiltered ? this.state.features : this.props.features;
        return(
            <div className='project-List section'>
                <div className='search-section'>
                    <input type='text' placeholder={t('Global.Search')} onChange={this.handleChange}/>
                </div>
                { features && features.map(feature=>{
                    return(
                        <Link to={'/'+i18n.language +'/feature/'+ feature.title.replace(/\s+/g, '-').toLowerCase()  + '/' + feature.id}  key={feature.id}>
                            <FeatureSummary feature={feature} />
                        </Link>
                    )
                })}
            </div>
        )
    }
}

export default withTranslation()(FeaturesList);