import React from 'react';

const Map = () => {
    const MapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3186.6166160944204!2d35.259076793560496!3d36.9950835118309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc87003723eb9fa36!2zS2l6YSDEsMWfIE1lcmtlemk!5e0!3m2!1sen!2str!4v1594887998618!5m2!1sen!2str";

    return(
        <section className="page-section location-section">
            <iframe src={MapUrl}
            width="100%"
            height="256"
            title="map">
            </iframe>
        </section>
    )
}


export default Map;