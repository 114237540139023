export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) =>{
        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(()=> {
            dispatch({type: 'LOGIN_SUCCESS'})
        }).catch((err) => {
            dispatch({type: 'LOGIN_ERROR', err})
        })
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) =>{
        const firebase = getFirebase();
        firebase.auth().signOut().then(()=> {
            dispatch({ type: 'SIGNOUT_SUCCESS' })
         });
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp)=>{
            return firestore.collection('users').doc(resp.user.uid).set({
                email: newUser.email,
                position: newUser.position,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                initials: newUser.firstName[0] + newUser.lastName[0]
            })
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' })
        }).catch(err => {
            dispatch({ type: 'SIGNUP_ERROR', err })
        })
    }
}

export const updateProfile = (profile) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        const authId = getState().firebase.auth.uid;
        
        profile.file = "uploaded";
        //console.log(profile);
        firestore.collection('users')
        .doc(authId)
        .set({
          ...profile,
          initials: profile.firstName[0] + profile.lastName[0]
        }).then(() => {
            console.log('updated')
            dispatch({ type: 'UPDATE_PROJECT', profile })
        }).catch((err) => {
            console.log(err)
            dispatch({type: 'UPDATE_PROJECT_ERROR', err});
        })
    }
  }
  
  export const deleteProfile = uid => {
      return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        console.log(firebase.auth().currentUser);
        firestore.collection('users').doc(uid).delete()
        .then(() =>{
            firebase.auth().currentUser.delete()
          .then(() => {
              console.log('deleted')
            dispatch({ type: 'DELETE_PROFILE', uid })
          }).catch(err => {
            dispatch({ type: 'DELETE_PROFILE_ERROR', err });
            console.log(err)
        })
        })
      }
    };