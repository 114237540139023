import React, { Component } from 'react';
import HomeSlider from './slider/HomeSlider';
import Steps from './steps/Steps';
import Map from './map/Map';
import Features from './features/Features';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18next';

class Home extends Component{
    render(){
        const { projects, i18n, auth, features } = this.props;
        // console.log(this.props)

        if(auth.uid){
            return <Redirect to={'/' + i18n.language +'/dashboard'} />
        } else{
            return(
                <div className={i18n.language === 'ar' ? 'home-section main-section-holder home-holder tar arDr' : 'home-section main-section-holder home-holder'}>
                    {isLoaded(projects)
                    ? 
                    <HomeSlider projects={projects} />
                    : 
                    <div className="loading-img">
                        <img src="/images/loading.svg" alt="Loading.." />
                    </div>
                    }
                    {isLoaded(features)
                    ? 
                    <Features features={features} />
                    : 
                    <div className="loading-img">
                        <img src="/images/loading.svg" alt="Loading.." />
                    </div>
                    }
                    <Steps />
                    <Map />
                </div>
            )
        }
    }
}
const mapStateToProps = (state) =>{
    //console.log(state);
    //console.log(i18n.language)
    return {
        projects: i18n.language === 'en' ? state.firestore.ordered.projects : state.firestore.ordered.projectsAr,
        features: i18n.language === 'en' ? state.firestore.ordered.features : state.firestore.ordered.featuresAr,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        //console.log(props);
        //console.log(State);
        return [
        { collection: i18n.language === 'en' ? 'projects' : 'projectsAr',
        //where: [['file', '==', 'uploaded']],
        orderBy: ['createdAt', 'desc']
        },
        { collection: i18n.language === 'en' ? 'features' : 'featuresAr',
        //where: [['file', '==', 'uploaded']],
        orderBy: ['createdAt', 'desc']
        }
    ]})
)(withTranslation()(Home));