import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import SignedInLinks from './SignedInLinks';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons'


const Navbar = (props) =>{
    const { i18n, t } = useTranslation();
    // website lang:
    //console.log(i18n.language);
    const handleLangChange = (lang) =>{
        window.location.replace('/' + lang + '/dashboard');
    }
   
    const toggleNav = () =>{
        const navEle = document.querySelector('#navbarResponsive');
        navEle.classList.contains('show') ? navEle.classList.remove('show') : navEle.classList.add('show');
    }

    const handleHomeLangChange = (lang) =>{
        window.location.replace('/' + lang);
    }
    const { auth, profile } = props;
    // const links = auth.uid ? <SignedInLinks profile={profile}/> : <SignedOutLinks/>;
    const isSigns = window.location.href.includes('signup') || window.location.href.includes('managepan');
    if(!isSigns && !auth.uid){
        window.onscroll = function() {
            const navEle = document.querySelector('#mainNav');
            window.pageYOffset > 10 ? navEle.classList.add("navbar-scrolled") : navEle.classList.remove("navbar-scrolled");
        };
    }
    return (
        auth.uid ? 
        <nav className={i18n.language === 'ar' ? 'nav-wrapper grey darken-3 fr arDr tar' : 'nav-wrapper grey darken-3 fl'}>
                <Helmet>
                    <title>{t('NavBar.SiteTitle')}</title>
                    <meta name="description" content={t('NavBar.SiteContent')} />
                </Helmet> 
                <div className='inner-nav-container'>
                    <div>
                        <Link to={'/' + i18n.language + '/dashboard'} className='astro-logo'>{t('Global.BrandName')}</Link>
                    </div>
                    <SignedInLinks profile={profile}/>
                    {/* <SignedOutLinks/> */}
                    <span className={i18n.language === 'ar' ? 'lang-title fr' : 'lang-title fl'}>
                    <FontAwesomeIcon icon={faLanguage} />
                    </span>
                    <ul className={i18n.language === 'ar' ? 'lang-btns fr' : 'lang-btns fl'}>
                        <li className={i18n.language === 'ar' ? 'fr selected' : 'fl'}><button onClick={()=> {handleLangChange('ar')}}>AR</button></li>
                        <li className={i18n.language === 'ar' ? 'fr' : 'fl selected'}><button onClick={()=> {handleLangChange('en')}}>EN</button></li>
                    </ul>
                </div>
        </nav>
        : !isSigns
        ?
        <div className="home-navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <Helmet>
                <title>{t('NavBar.SiteTitle')}</title>
                <meta name="description" content={t('NavBar.SiteContent')} />
            </Helmet> 
            <div className="container">
                <div className='inner-nav-holder'>
                <a className="navbar-brand js-scroll-trigger hiden-mobile" href={"/" + i18n.language}>
                    <img src="/images/logo.png" className="logo" alt="logo"/>
                </a>
                <a className="navbar-brand js-scroll-trigger desktop-mobile" href={"/" + i18n.language}>
                    <img src="/images/logo_1.png" className="logo scrolled-logo" alt="logo"/>
                </a>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto my-2 my-lg-0">
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#features">{t('NavBar.OurSpecialties')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#whyus">{t('NavBar.WorkingMethod')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href={"/" + i18n.language + "/aboutus/about-us"}>{t('NavBar.AboutUs')}</a>
                        </li>
                    </ul>
                </div>
                <div className="header-language">
                    <ul className="lang-btns">
                        <li className={i18n.language === 'ar' ? 'selected fl' : 'fl'}><button onClick={()=> {handleHomeLangChange('ar')}}>AR</button></li>
                        <li className={i18n.language === 'ar' ? 'fl' : 'selected fl'}><button onClick={()=> {handleHomeLangChange('en')}}>EN</button></li>
                    </ul>
                </div>
                <button className="navbar-toggler navbar-toggler-right" type="button" onClick={()=> {toggleNav()}}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                
                <a className="navbar-brand js-scroll-trigger hiden-mobile" href={"/" + i18n.language}>
                    <img src="/images/logo_1.png" className="logo scrolled-logo" alt="Logo"/>
                </a>
                
            </div>
                </div>
        </div>
        : null
    )
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Navbar);