const initState = {
    projects:[],
    profiles: [],
    aboutUs: []
};

const aboutUsReducer = (state = initState, action) => {
    switch (action.type){
        case 'UPDATE_ABOUTUS':

            return state;
        case 'UPDATE_ABOUTUS_ERROR':

            return state;
        default:
            return state;
    }
}

export default aboutUsReducer;