import i18n from "../../i18next";

export const createProject = (project) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authId = getState().firebase.auth.uid;
        
        project.file = "uploaded";
        //console.log(project);
        firestore.collection(i18n.language === 'en' ? 'projects' : 'projectsAr').add({
            ...project,
            authorFirstName: profile.firstName,
            authorLastName: profile.lastName,
            authorId: authId,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_PROJECT', project })
        }).catch((err) => {
            dispatch({type: 'CREATE_PROJECT_ERROR', err});
        })
    }
}

export const editProject = (project) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
      // make async call to database
      const firestore = getFirestore();
      const profile = getState().firebase.profile;
      const authId = getState().firebase.auth.uid;
      
      project.file = "uploaded";
      firestore.collection(i18n.language === 'en' ? 'projects' : 'projectsAr')
      .doc(project.id)
      .set({
        ...project,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId: authId,
        createdAt: project.createdAt
      }).then(() => {
          dispatch({ type: 'UPDATE_PROJECT', project })
      }).catch((err) => {
          dispatch({type: 'UPDATE_PROJECT_ERROR', err});
      })
  }
}

export const deleteProject = id => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      
      firestore.collection(i18n.language === 'en' ? 'projects' : 'projectsAr').doc(id)
        .delete()
        .then(() => {
          dispatch({ type: 'DELETE_PROJECT', id })
        }).catch(err => {
          dispatch({ type: 'DELETE_PROJECT_ERROR', err })
      })
    }
  };

//export const deleteProject = (project) => {
//    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
//        const firestore = getFirestore();
//        console.log('will delete');
//        firestore.collection(i18n.language === 'en' ? 'projects' : 'projectsAr').delete()
//        .then(() => {
//            dispatch({ type: 'DELETE_PROJECT', project })
//        }).catch((err) => {
//            dispatch({type: 'DELETE_PROJECT_ERROR', err});
//        })
//    }
//}