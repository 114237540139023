import React from 'react';
import moment from 'moment';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';

const Slides = ({project}) => {
    const { i18n } = useTranslation();
    moment.locale(i18n.language);
    return(
        <div className="inner-slide">
            <img src={ project.imageUrl } alt={project.title} />
            <h2>
                {project.title}
            </h2>
        </div>
    )
}


export default Slides;