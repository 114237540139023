export const editAboutUs = (aboutUs) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        
        aboutUs.file = "uploaded";
        firestore.collection('aboutUs')
        .doc(aboutUs.id)
        .set({
          ...aboutUs
        }).then(() => {
            dispatch({ type: 'UPDATE_ABOUTUS', aboutUs })
        }).catch((err) => {
            dispatch({type: 'UPDATE_ABOUTUS_ERROR', err});
        })
    }
  }