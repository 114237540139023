import authReducer from "./authReducer";
import projectReducer from "./projectReducer";
import featureReducer from "./featureReducer";
import aboutUsReducer from "./aboutUsReducer";
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';


const rootReducer = combineReducers({
    auth: authReducer,
    project: projectReducer,
    feature: featureReducer,
    aboutUs: aboutUsReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

export default rootReducer;