import React from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Features from '../features/Features';
import Map from "../../home/map/Map";
import Slogan from "../../home/slogan/Slogan"

const HomeAboutUs = (props) =>{

    const { aboutUs, features } = props;
    const { t, i18n } = useTranslation();

    //console.log(aboutUs);

        moment.locale(i18n.language);
        if (aboutUs){
                return(
                    <div className='product-page-holder'>
                    <div className={i18n.language === 'ar' ? 'card z-depth-0 tar arDr' : 'card z-depth-0'}>
                        { aboutUs.imageUrl !== "" ?
                        <div className='card-image'>
                            <img src={aboutUs.imageUrl} alt={aboutUs.title} />
                        </div>
                        :
                        <div className='card-image'>
                            <img src="/images/loading.svg" alt="Loading" />
                        </div>
                        }
                        <div className='card-content'>
                        <Helmet>
                            <title>{aboutUs.title}</title>
                            <meta name="description" content={aboutUs.content} />
                        </Helmet>
                            <div className='card-title'>
                                <div className="container">
                                    <h1>
                                        {aboutUs.title}
                                    </h1>
                                </div>
                            </div>
                            <div className="card-article">
                                <div className="container">
                                    <div className="article-holder">
                                        <article dangerouslySetInnerHTML={{__html: aboutUs.content}}></article>
                                    <div className={i18n.language === 'ar' ? "contact-methods arDr" : "contact-methods"}>
                                        <p>{t('ProductPage.ForMoreInfo')}</p>
                                        <ul className="contact-methods-icons">
                                                <li>
                                                    <a href="tel:+905308939477"><FontAwesomeIcon icon={faPhone} /></a>
                                                </li>
                                                <li>
                                                    <a href="mailto:info@ilaf-trade.com">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.facebook.com/ilaftrade" target="_blank" rel="noopener noreferrer">
                                                        <FontAwesomeIcon icon={faFacebook} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/ilaftrade/" target="_blank" rel="noopener noreferrer">
                                                        <FontAwesomeIcon icon={faInstagram} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://api.whatsapp.com/send?phone=+905308939477&text=مرحباً, أنا أتواصل معكم عن طريق موقعكم الالكتروني">
                                                        <FontAwesomeIcon icon={faWhatsapp} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/ilaf-trade-company-1672811b0/">
                                                        <FontAwesomeIcon icon={faLinkedin} />
                                                    </a>
                                                </li>
                                            </ul>
                                    </div>
                                    </div>
                                </div>
                               
                            </div>
                            <Slogan/>
                            <div className="alsoRead">
                                <div className="container">
                                <h2>
                                    {t('Global.AlsoRead')}
                                </h2>
                                </div>
                                {isLoaded(features)
                                ? 
                                <Features features={features} />
                                : 
                                <div className="loading-img">
                                    <img src="/images/loading.svg" alt="Loading.." />
                                </div>
                                }
                                </div>
                            
                            <Map />
                        </div>
                    </div>
                </div>
                )
            
        } else{
            return(
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            )
        }
    
    
}

const mapStateToProps = (state, ownProps) => {
    
    const culture = i18n.language;
    const id = culture === "ar" ? "E8D17umPVbR1vrwO6gLq" : "XZ1jopzynp7SsnjfTpXx";
    const aboutUs = state.firestore.data.aboutUs ? state.firestore.data.aboutUs[id] : null;
    return{
        aboutUs : aboutUs,
        features: i18n.language === 'en' ? state.firestore.ordered.features : state.firestore.ordered.featuresAr,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(() => {
        return [
        {collection: 'aboutUs'},
        {collection: i18n.language === 'en' ? 'features' : 'featuresAr'}
    ]})
)(HomeAboutUs);