
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const Footer = (props) =>{
    const { i18n, t } = useTranslation();
    const { auth } = props;    
    return (
        !auth.uid ? 
        <section className={i18n.language === 'ar' ? "page-section arDr tar" : "page-section"}  id="contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col m6">
                                        <div className="footer-title">
                                            <p>
                                            {t('Footer.Contact')}<br/>{t('Footer.Us')}
                                            </p>
                                            <ul className="contact-methods-icons">
                                                <li>
                                                    <a href="tel:+905308939477"><FontAwesomeIcon icon={faPhone} /></a>
                                                </li>
                                                <li>
                                                    <a href="mailto:info@ilaf-trade.com">
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.facebook.com/ilaftrade" target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faFacebook} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/ilaftrade/" target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faInstagram} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={"https://api.whatsapp.com/send?phone=+905308939477&text=" + t('Footer.HiIamContactingYou')} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faWhatsapp} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/ilaf-trade-company-1672811b0/">
                                                        <FontAwesomeIcon icon={faLinkedin} />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="footer-info">
                                            <a href="https://www.google.com/maps?ll=36.996122,35.257614&z=15&t=m&hl=en&gl=TR&mapclient=embed&cid=14443047794190711350" target="_blank" rel="noopener noreferrer">
                                                <span>
                                                {t('Footer.AdanaTurkey')}
                                                </span>
                                            </a>
                                            <a href="tel:+905308939477">
                                                <strong>
                                                +90 530 893 94 77
                                                </strong>
                                            </a>
                                            <a href="mailto:info@ilaf-trade.com">
                                                <p>
                                                    info@ilaf-trade.com
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
        : null
    )
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Footer);