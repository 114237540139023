import React from 'react';
import moment from 'moment';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';

const ProjectSummary = ({project}) => {
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    //console.log(project);
    return(
        <div className='card z-depth-0 project-summary'>
            <div className='card-content grey-text text-darken-3'>
                <span className='card-title'>{project.title}</span>
                <p>{t('Global.PostedBy')}: {project.authorFirstName} {project.authorLastName}</p>
                <p className='gray-text'>{moment(project.createdAt.toDate()).calendar()}</p>
            </div>
        </div>
    )
}


export default ProjectSummary;