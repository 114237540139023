import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ar';
import ProjectSummary from '../projects/ProjectSummary';
import { useTranslation } from 'react-i18next';
import {deleteProfile} from '../../store/actions/authActions';
import i18n from '../../i18next';

const UserProfile = (props) =>{
    const { user, auth } = props;
    const { t, i18n } = useTranslation();
    const projects = props.projects;
    const handleDelete = () => {
        const id = auth.uid;
        props.deleteProfile(id);
        //props.history.push('/');
        // you can push to dashboard after deleting...
    }


    moment.locale(i18n.language);

    if (!auth.uid) {
        return <Redirect to={'/' + i18n.language} />
    } else {
        if (user){
            return(
                <div className='main-section-holder section project-details'>
                    <div className={i18n.language === 'ar' ? 'card z-depth-0 tar arDr' : 'card z-depth-0'}>
                        <div className='card-content'>
                            <span className='card-title'>{user.firstName} {user.lastName}</span>
                            <p>{user.Email ? user.Email + ': ' + t('Global.Email') : null}</p>
                            <p>{user.position ? user.position + ': ' + t('Global.Position') : null} </p>
                        </div>
                        {props.match.params.uid === props.auth.uid &&
                        <div className='action-btns-sec card-action'>
                            <button className="waves-effect waves-light btn red" onClick={()=> {handleDelete()}}>{t('Global.Delete')}</button>
                            <a href={"/" + i18n.language + '/updateprofile/' + user.firstName + '-' + user.lastName + '/' + auth.uid} className="waves-effect waves-light btn blue">{t('Global.Edit')}</a>
                        </div>
                        }
                    </div>
                    {projects !== undefined && projects.length > 0
                    ?
                    <div className="user-projects">
                        <h5 className={i18n.language === 'ar' ? 'grey-text tar arDr' : 'grey-text'}>{t('Global.UserProducts')}</h5>
                        <div className='row row-with-margin'>
                        {
                            projects.map(project =>{
                                return (
                                    <a href={'/'+i18n.language +'/project/'+ project.title.replace(/\s+/g, '-').toLowerCase()  + '/' + project.id}  key={project.id} className='col s6'>
                                        <ProjectSummary project={project} />
                                    </a>
                                )
                            })
                        }
                        </div>
                    </div>
                    : null}
                </div>
            )
        } else{
            return(
            <div className='main-section-holder center'>
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
            )
        }
    }
    
}

const mapStateToProps = (state, ownProps) => {
    //console.log(ownProps.match.params.uid);
    //console.log(state);
    const id = ownProps.match.params.uid;
    const users = state.firestore.data.users;
    const user = users ? users[id] : null;
    return{
        user : user,
        auth: state.firebase.auth,
        projects: state.firestore.ordered.projects
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteProfile: (id) => dispatch(deleteProfile(id))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        return [
        {collection: 'users'},
        {
            collection: i18n.language === 'en' ? 'projects' : 'projectsAr',
            where: [['authorId', '==', props.match.params.uid]]
        }
    ]})
)(UserProfile);