import React from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';
import {deleteProject} from '../../store/actions/projectActions'
import fbConfig from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';

const ProjectDetail = (props) =>{

    const { project, auth } = props;
    const { t, i18n } = useTranslation();

    //console.log(project);

    const handleDelete = () => {
            const id = props.match.params.id;
            const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/projectsimages/'+project.imageName);
            if(project.imageUrl !== ""){
                storageRefOfCurrentFile.delete();
                props.deleteProject(id);
            }else{
                props.deleteProject(id);
            }
            
            props.history.push('/' + i18n.language + '/dashboard');
            // you can push to dashboard after deleting...
    }
        moment.locale(i18n.language);
        if (project){
            if(!auth.uid) {
                return <Redirect to={'/' + i18n.language} />
            }
            else{
                return(
                    <div className='main-section-holder section project-details'>
                        <Helmet>
                        <title>{project.title}</title>
                        <meta name="description" content={project.content} />
                        </Helmet>
                        <div className={i18n.language === 'ar' ? 'card z-depth-0 tar arDr' : 'card z-depth-0'}>
                            { project.imageUrl !== "" &&
                            <div className='card-image'>
                                <img src={project.imageUrl} alt={project.title} />
                            </div>
                            }
                            <div className='card-content'>
                                <h1 className='card-title'>{project.title}</h1>
                                <p dangerouslySetInnerHTML={{__html: project.content}}></p>
                            </div>
                            <div className='card-action gret liten-4 grey-text'>
                                <div>{t('Global.PostedBy')}: {project.authorFirstName} {project.authorLastName}</div>
                                <div>{moment(project.createdAt.toDate()).calendar()}</div>
                                <div className='tags-section'>
                                    {project.tag != null &&
                                    <div className="chip">
                                    Tag
                                    </div>
                                    }
                                </div>
                            </div>
                            {auth.uid !== undefined &&
                            <div className='action-btns-sec card-action'>
                            <button className="waves-effect waves-light btn red" onClick={()=> {handleDelete()}}>
                                {t('Global.Delete')} <FontAwesomeIcon icon={faTrashAlt}/>
                                </button>
                            <a href={"/" + i18n.language + '/editproject/' + project.title.replace(/\s+/g, '-').toLowerCase() + '/' + props.match.params.id} className="waves-effect waves-light btn blue">
                                {t('Global.Edit')} <FontAwesomeIcon icon={faEdit}/>
                                </a>
                            </div>
                            }
                            
                        </div>
                    </div>
                )
            }
            
        } else{
            return(
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            )
        }
    
    
}

const mapStateToProps = (state, ownProps) => {
    
    //console.log(i18n.language);
    const id = ownProps.match.params.id;
    const arProjects = state.firestore.data.projectsAr;
    const enProjects = state.firestore.data.projects;
    const projects = {
        ...arProjects,
        ...enProjects
    };
    const project = projects ? projects[id] : null;
    return{
        project : project,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteProject: (id) => dispatch(deleteProject(id))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        return [
        {collection: 'projects'},
        {collection: 'projectsAr'}
    ]})
)(ProjectDetail);