import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Dashboard from './components/dashboard/Dashboard';
import Home from './components/home/Home';
import ProjectDetails from './components/projects/ProjectDetails';
import ProductDetails from './components/home/slider/ProductDetail';
import AboutUs from './components/aboutUs/AboutUs';
import HomeAboutUs from './components/home/aboutUs/AboutUs';
import FeatureDetail from './components/home/features/FeatureDetail';
import SignIn from './components/auth/SignIn';
// import SignUp from './components/auth/SignUp';
import ErrorPage from './components/errorPage/ErrorPage';
import CreateProject from './components/projects/CreateProject';
import EditProject from './components/projects/EditProject';
import EditAboutUs from './components/aboutUs/EditAboutUs';
import UsersDashboard from './components/users/UsersDashboard';
import UpdateProfile from './components/users/UpdateProfile';
import UserProfile from './components/users/UserProfile';
import FeaturesDashboard from './components/features/FeaturesDashboard';
import FeatureDetails from './components/features/FeatureDetails';
import CreateFeature from './components/features/CreateFeature';
import EditFeature from './components/features/EditFeature';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Navbar/>
      <Switch>
        <Route exact path='/:culture/dashboard' component={Dashboard} />
        <Route exact path='/:culture/managepan' component={SignIn} />
        {/* <Route exact path='/:culture/signup' component={SignUp} /> */}
        <Route exact path='/:culture/project/:title/:id' component={ProjectDetails} />
        <Route exact path='/:culture/product/:title/:id' component={ProductDetails} />
        <Route exact path='/:culture/dashboardabout/:title/:id' component={AboutUs} />
        <Route exact path='/:culture/aboutus/:title' component={HomeAboutUs} />
        <Route exact path='/:culture/editaboutus/:title/:id' component={EditAboutUs} />
        <Route exact path='/:culture/featuredetail/:title/:id' component={FeatureDetail} />
        <Route exact path='/:culture/createproject' component={CreateProject} />
        <Route exact path='/:culture/editproject/:title/:id' component={EditProject} />
        <Route exact path='/:culture/usersdashboard' component={UsersDashboard} />
        <Route exact path='/:culture/user/:fullname/:uid' component={UserProfile} />
        <Route exact path='/:culture/updateprofile/:title/:id' component={UpdateProfile} />
        <Route exact path='/:culture/createfeature' component={CreateFeature} />
        <Route exact path='/:culture/featuresdashboard' component={FeaturesDashboard} />
        <Route exact path='/:culture/feature/:title/:id' component={FeatureDetails} />
        <Route exact path='/:culture/editfeature/:title/:id' component={EditFeature} />
        <Route exact path='/' component={Home} />
        <Route exact path='/:culture' component={Home} />
        <Route component={ErrorPage} />
      </Switch>
      <Footer/>
    </div>
    </BrowserRouter>
    
  );
}
   
export default App;
