import React, {Component} from 'react';
import ProjectSummary from './ProjectSummary';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ProjectList extends Component {
    state = {
        projects: "",
        isFiltered: false
    }
    handleChange = (e) =>{
        const projects = this.props.projects;
        const filteredProjects = projects.filter( project => {
        if(e.target.value.length > 0){
            return project.title.toLowerCase().includes(e.target.value.toLowerCase());
        }else{
                return project;
            }       
        })
        this.setState({
            projects: filteredProjects,
            isFiltered: true
        })
        
    }
    render(){
        const { i18n, t } = this.props;
        const projects = this.state.isFiltered ? this.state.projects : this.props.projects;
        return(
            <div className='project-List section'>
                <div className='search-section'>
                    <input type='text' placeholder={t('Global.Search')} onChange={this.handleChange}/>
                </div>
                { projects && projects.map(project=>{
                    return(
                        <Link to={'/'+i18n.language +'/project/'+ project.title.replace(/\s+/g, '-').toLowerCase()  + '/' + project.id}  key={project.id}>
                            <ProjectSummary project={project} />
                        </Link>
                    )
                })}
            </div>
        )
    }
}

export default withTranslation()(ProjectList);