import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const UserSummary = ({user}) => {
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    return(
        <div className='card z-depth-0 user-summary'>
            <div className='grey-text text-darken-3'>
                <div className='user-photo'>
                    <img src={user.imageUrl ? user.imageUrl : '../../../images/profile.jpg'} alt="user" />
                </div>
                <span className='card-title'>{user.firstName} {user.lastName}</span>
                <p>{t('Global.Email')}: {user.email ? user.email : ""}</p>
                <p>{user.position ? t('Global.Position')+': '+ user.position : ""}</p>
            </div>
        </div>
    )
}


export default UserSummary;