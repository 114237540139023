import React, { Component } from 'react';
import UserList from './UserList';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class UsersDashboard extends Component{
    render(){
        const { users, auth, i18n } = this.props;
        //console.log(this.props);
        if(!auth.uid){
            return <Redirect to={'/' + i18n.language} />
        } else{
            return(
                <div className={i18n.language === 'ar' ? 'dashboard main-section-holder tar arDr' : 'dashboard main-section-holder'}>
                    <div className='row'>
                        <div className='col s12 m12'>
                            {isLoaded(users)
                            ? 
                            <UserList users={users} />
                            :
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            )
        } 
    }
}
const mapStateToProps = (state) =>{
    //console.log(state);
    //console.log(i18n.language)
    return {
        users: state.firestore.ordered.users,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((Props, State) => {
        //console.log(Props);
        //console.log(State);
        return [
        { collection: 'users', limit: 7 }
    ]})
)(withTranslation()(UsersDashboard));