import React from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';
import i18n from '../../i18next';


const AboutUs = (props) =>{

    const { aboutUs, auth } = props;
    const { t, i18n } = useTranslation();

    //console.log(aboutUs);

        moment.locale(i18n.language);
        if (aboutUs){
            if(!auth.uid) {
                return <Redirect to={'/' + i18n.language} />
            }
            else{
                return(
                    <div className='main-section-holder section project-details'>
                        <Helmet>
                        <title>{aboutUs.title}</title>
                        <meta name="description" content={aboutUs.content} />
                        </Helmet>
                        <div className={i18n.language === 'ar' ? 'card z-depth-0 tar arDr' : 'card z-depth-0'}>
                            
                            <div className='card-image'>
                                <img src={aboutUs.imageUrl ? aboutUs.imageUrl : "/images/about-us-banner.jpg"} alt={aboutUs.title} />
                            </div>
                            
                            <div className='card-content'>
                                <h1 className='card-title'>{aboutUs.title}</h1>
                                <p dangerouslySetInnerHTML={{__html: aboutUs.content}}></p>
                            </div>
                            {auth.uid !== undefined &&
                            <div className='action-btns-sec card-action'>
                            <a href={"/" + i18n.language + '/editaboutus/' + aboutUs.title.replace(/\s+/g, '-').toLowerCase() + '/' + props.match.params.id} className="waves-effect waves-light btn blue">
                                {t('Global.Edit')} <FontAwesomeIcon icon={faEdit}/>
                                </a>
                            </div>
                            }
                            
                        </div>
                    </div>
                )
            }
            
        } else{
            return(
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            )
        }
    
    
}

const mapStateToProps = (state, ownProps) => {
    
    const culture = i18n.language.toString();
    const id = culture === "ar" ? "E8D17umPVbR1vrwO6gLq" : "XZ1jopzynp7SsnjfTpXx";
    const aboutUs = state.firestore.data.aboutUs ? state.firestore.data.aboutUs[id] : null;
    return{
        aboutUs : aboutUs,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(() => {
        return [
        {collection: 'aboutUs'}
    ]})
)(AboutUs);