import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18next';

class SignIn extends Component {
    state = {
        email: "",
        password: ""
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state)
    }
    handleLangChange = (lang) =>{
        i18n.changeLanguage(lang);
        window.location.replace('/' + i18n.language + '/dashboard');
    }
    render(){
        const { authError, auth, t, i18n } = this.props;
    if (auth.uid) {
        return <Redirect to={'/' + i18n.language + '/dashboard'} />
    } else{
        return(
            <div className="main-section-holder sign-in">
                <form onSubmit={this.handleSubmit} className={i18n.language === 'ar' ? 'white tar arDr' : 'white tal'}>
                    <ul className={i18n.language === 'ar' ? 'lang-btns outer fr' : 'lang-btns outer fl'}>
                        <li className={i18n.language === 'ar' ? 'fr selected' : 'fl'}><span onClick={()=> {this.handleLangChange('ar')}}>العربية</span></li>
                        <li className={i18n.language === 'ar' ? 'fr' : 'fl selected'}><span onClick={()=> {this.handleLangChange('en')}}>English</span></li>
                    </ul>
                    <h5 className="grey-text text-darken-3">{t('Global.LogIn')}</h5>
                    <div className="input-field">
                        <label htmlFor="email" className={i18n.language === 'ar' ? 'autoLabel-right' : ''}>{t('Global.Email')}</label>
                        <input type="email" id="email" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="password" className={i18n.language === 'ar' ? 'autoLabel-right' : ''}>{t('Global.Password')}</label>
                        <input type="password" id="password" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <button className="btn pink lighten-1 z-depth-0 waves-effect waves-light">
                        {t('Global.LogIn')}
                        </button>
                        <div className='red-text center'>
                        { authError ? <p>{authError}</p> : null}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
        
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignIn));