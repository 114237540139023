import React from 'react';
import { useTranslation } from 'react-i18next';

const Steps = () => {
    const { t } = useTranslation();
    return(
        <section className="page-section steps-section arDr" id="whyus">
                            <div className="container">
                                <div className="inner-features">
                                    <div className="row">
                                        <div className="col m3">
                                            <div className="single-feature">
                                                <img src="images/features/email.png" alt="" />
                                                <p>{t('Steps.ContactUs')}</p>
                                            </div>
                                        </div>
                                        <div className="col m3">
                                            <div className="single-feature">
                                                <img src="images/features/conversation.png" alt="" />
                                                <p>{t('Steps.LetsDiscussAboutYourRequirement')}</p>
                                            </div>
                                        </div>
                                        <div className="col m3">
                                            <div className="single-feature">
                                                <img src="images/features/arrival.png" alt="" />
                                                <p>{t('Steps.YourWellcome')}</p>
                                            </div>
                                        </div>
                                        <div className="col m3">
                                            <div className="single-feature">
                                                <img src="images/features/congrats.png" alt="" />
                                                <p>{t('Steps.Congrats')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </section>
    )
}


export default Steps;