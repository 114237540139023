import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { editProject } from '../../store/actions/projectActions';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ar';
import { withTranslation } from 'react-i18next';
import fbConfig from '../../config/fbConfig';
import i18n from '../../i18next';
import JoditEditor from "jodit-react";


class EditProject extends Component {
    state = {
        id: "",
        title: "",
        content: "",
        file: "",
        imageUrl: "",
        imageName: "",
        createdAt: "",
        isUpodated: false,
        isloadingImage: false,
        isSubmit: false
    }
    
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            isUpodated: true
        })
    }
    handleChangeTextarea = (value) => {
        this.setState({
            content: value
        })
    }
    handleImageChange = (e) => {
        if (e.target.files[0] !== undefined){
            if (this.state.file !== ""){
                this.handleDeleteImage(e);
                this.setState({
                    file: e.target.files[0],
                    isUpodated: true,
                    isloadingImage: true
                });
            } else {
                this.setState({
                    file: e.target.files[0],
                    isUpodated: true,
                    isloadingImage: true
                });
            }
                
        }
    }

    handleUploadImage = () => {
        const imageNickName = Date.now();
        const FinalimageNickName = imageNickName+this.state.file.name
        const bucketName = 'images/projectsimages';
        const file = this.state.file;
        const storageRef =  fbConfig.storage().ref(`${bucketName}/${FinalimageNickName}`);
        const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/projectsimages/'+FinalimageNickName);
        const uploadTask = storageRef.put(file);
        
            uploadTask
            .then(()=>{
                storageRefOfCurrentFile.getDownloadURL()
                .then((url) => {
                    this.setState({
                        imageUrl: url,
                        imageName:FinalimageNickName,
                        isloadingImage: false
                    });
                });
            });
    }

    handleDeleteImage = (e) => {
        e.preventDefault();
        const storageRefOfCurrentFile = fbConfig.storage().ref().child('images/projectsimages/'+this.state.imageName);
        
        storageRefOfCurrentFile.delete()
            .then(()=>{
                this.setState({
                    imageUrl: "",
                    imageName: "",
                    file: "",
                    isUpodated: true
                });
                this.props.editProject(this.state);
                });
            
        //storage.ref().child(uid).child(img).delete()
        //database.ref().child(uid).child(img).remove()
      }

    handleSubmit = (e) => {
        e.preventDefault();
        // submit new project
        this.setState({isSubmit : true})
        this.props.editProject(this.state);
        this.props.history.push('/' + i18n.language + '/dashboard');
        
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.file !== this.state.file && this.state.file !== ""){
            this.handleUploadImage();
        }
        if(prevProps.project !== this.props.project){
            this.setState({
                id: this.props.match.params.id,
                title: this.props.project.title,
                content: this.props.project.content,
                imageUrl: this.props.project.imageUrl,
                imageName:this.props.project.imageName ? this.props.project.imageName : "",
                createdAt: this.props.project.createdAt.toDate()
            });
        }  
    }
    
    render(){
        const { project, auth, t, i18n } = this.props;
        moment.locale(i18n.language);
    if (!auth.uid) {
        return <Redirect to={'/' + i18n.language} />
    } else {
        if (project){
            return(
                <div className="main-section-holder">
                    <form onSubmit={this.handleSubmit} className={i18n.language === 'ar' ? 'product-form white tar arDr' : 'product-form white tal'}>
                        {this.state.isSubmit ? 
                        <div className="loading-submit">
                            <img src="/images/loading.svg" alt="Loading" />
                        </div>
                        : null}
                        <h5 className="grey-text text-darken-3">{t('Global.EditProduct')}</h5>
                        <div className="input-field">
                            <label htmlFor="title" className={i18n.language === 'ar' ? 'autoLabel-right active' : 'active'}>{t('Global.Title')}</label>
                            <input type="text" id="title" onChange={this.handleChange} value={this.state.title}/>
                        </div>
                        <div className="input-field">
                            <JoditEditor value={project.content} onChange={this.handleChangeTextarea}/>
                        </div>
                        <div className="input-field">
                            <div className='btns-area'>
                            <input id="image" type='file' onChange={this.handleImageChange}/>
                            { this.state.imageUrl !== "" &&<button onClick={this.handleDeleteImage} className="btn delete-photo red">
                            {t('Global.DeleteImage')}
                            </button>}
                            </div>
                            {this.state.isloadingImage ?
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                            :    
                            this.state.imageUrl !== "" ? <img src={this.state.imageUrl} alt={this.state.title} id="project-image"/> : null 
                            }
                        </div>
                        <div className="input-field">
                            <button className="btn green lighten-1 z-depth-0 waves-effect waves-light">
                            {t('Global.Edit')}
                            </button>
                        </div>
                    </form>  
                
                </div>
            )
        } else{
            return(
            <div className='main-section-holder center'>
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
            )
        }
    }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const projects = i18n.language === 'en' ? state.firestore.data.projects : state.firestore.data.projectsAr;
    const project = projects? projects[id] : null;
    return{
        project : project,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        editProject: (project) => dispatch(editProject(project))
    }
}



export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((Props, State) => {
        return [
        {collection: i18n.language === 'en' ? 'projects' : 'projectsAr'}
    ]})
)(withTranslation()(EditProject));