const initState = {
    features:[],
    profiles: []
};

const featureReducer = (state = initState, action) => {
    switch (action.type){
        case 'CREATE_FEATURE':
            //console.log('created feature', action.feature);
            return state;
        case 'CREATE_FEATURE_ERROR':
            //console.log('create feature error', action.err);
            return state;
        case 'UPDATE_FEATURE':
            //console.log('created feature', action.feature);
            return state;
        case 'UPDATE_FEATURE_ERROR':
            //console.log('create feature error', action.err);
            return state;
        case 'DELETE_FEATURE':
            console.log('delete feature');
            return state;
        case 'DELETE_FEATURE_ERROR':
            console.log('delete feature error', 'state: ', state, 'action: ', action.feature);
            return state;
        default:
            return state;
    }
}

export default featureReducer;